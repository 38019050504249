import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import GearboxBrakeForm from '../components/global/GearboxBrakeForm';
import MiscComponentsForm from '../components/global/MiscComponentsForm';
import ServiceComponentForm from '../components/global/ServiceComponentForm';
import ServiceInfoForm from '../components/global/ServiceInfoForm';
import ServiceMotorInformation from '../components/global/ServiceMotorInformation';
import ServiceUnitInformation from '../components/global/ServiceUnitInformation';
import { StructureComponentForm } from '../components/global/StructureComponentForm';
import WristPinComponentForm from '../components/global/WristPinComponentForm';
import { apiGetAuth, apiPost } from '../util/ApiRequest';
import { ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';


import { useNavigate } from 'react-router-dom';



const CreateService = () => {
  const navigate = useNavigate();

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const [singeObj, setSingleObj] = useState({});


  const { id } = useParams();


  const [files, setFiles] = useState([]);


  useEffect(() => {
    if (id) {

      getSingleWell(id)
    }
  }, [id]);






  const getSingleWell = (wellId) => {
    setIsLoaderInfo(true);
    apiGetAuth(
      ENDPOINTS.WellSingle + wellId,
      (res) => {
        setSingleObj(res);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error, ' error');
        setIsLoaderInfo(false);
      },
    );
  };





  const handleSubmit = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    setIsLoaderInfo(true)
    apiPost(
      ENDPOINTS.ServiceCreate,
      body,
      (res) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully  login",
        });
        setIsLoaderInfo(false)
        navigate(`/admin/service-records/${id}`)


      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  };


  const onFileSelect = (e) => {
    setFiles([...files, ...e?.target?.files]);
  }

  return (
    <div className='container-fluid bg-white' >
      <div >

        <h3 className='text-center mb-4'>Create Services</h3>

        <form onSubmit={handleSubmit}>

          <div className='container custom-overly'>

            <ServiceInfoForm wellObj={singeObj} />

            <ServiceUnitInformation isCreated={true} />

            <ServiceMotorInformation isCreated={true} />

            <ServiceComponentForm isCreated={true} />

            <WristPinComponentForm isCreated={true} />

            <GearboxBrakeForm isCreated={true} />

            <MiscComponentsForm isCreated={true} />

            <StructureComponentForm isCreated={true} />

            <div className='container  mt-5  p-5 rounded-2 custom-boxshedow'>
              <h5 className='text-center'>Technician Comments </h5>
              <div className='row'>
                <div className='col-md-12 col-sm-12'>
                  <div className=' mb-3'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        name='technicianComments'
                        required={true}
                        placeholder='Comments' as="textarea" rows={3} />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <div className='container  mt-5  p-5 rounded-2 custom-boxshedow'>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>Photo</Form.Label>
                <Form.Control type="file" multiple required={true} onChange={onFileSelect} name={'media'} />
              </Form.Group>
              <Row className="px-3">
                {files.map((file, index) => {
                  return <Col key={index} className="mt-3 mr-2 border px-2 py-1" sm={2}
                  >
                    <i className="fa fa-times float-right" aria-hidden="true"
                      onClick={() => {
                        let newfiles = [...files]
                        newfiles.splice(index, 1)
                        setFiles([...newfiles])
                      }}
                    ></i>
                    {file.name}

                  </Col>
                })}
              </Row>
            </div>



            <div className='container  mt-5  p-5 rounded-2  text-end'>
              <button className='btn btn-danger me-2'>cancel</button>
              <button className='btn btn-primary'>Submit records</button>
            </div>


          </div>
        </form>

      </div>
    </div>
  )
}

export default CreateService