import React from 'react';
import Form from 'react-bootstrap/Form';

const LooseTightSelect = ({ name, label, setValue, defaultValue }) => {
    return (
        <>
            <label className="mb-2">{label}</label>
            <Form.Select onChange={(e) => setValue(e.target.value)} name={name} defaultValue={defaultValue}>
                <option value="Loose">Loose</option>
                <option value="Tight">Tight</option>
            </Form.Select>
        </>
    )
}

export default LooseTightSelect