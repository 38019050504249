import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { CgDanger } from "react-icons/cg";
import { FaBuilding, FaEdit, FaUserCircle } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { IoIosLogOut } from "react-icons/io";
import { IoDocumentsOutline } from "react-icons/io5";
import { MdClearAll, MdDashboard, MdEditLocationAlt } from "react-icons/md";
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';

const SidebarCustom = ({ burgerShow }) => {

  const navigate = useNavigate();

  
  
  const [modalShow, setModalShow] = useState(false);
  
  const [urlParam, setUrlParam] = useState(
    window.location.pathname.split("/").pop()
    );
    
    const [userType, setUserType] = useState('');
  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);

  const logOut = () => {

    localStorage.clear();
    window.location.href = `/`;
  }
  return (
    <div className="position-relative">

      <Sidebar
        defaultCollapsed={burgerShow}

        breakPoint="lg"
        className="sidebar-dashboard">

        <Menu>

          {['0', '1', '2'].includes(userType) && (
            <MenuItem
              active={urlParam === "dashboard" ? true : false}
              onClick={() => {
                navigate('/admin/dashboard')
                setUrlParam("dashboard")
              }}
              icon={<MdDashboard />}
              className="sidebar-menu-design sidebar-menu-design-custom"
            >
              Dashboard
            </MenuItem>
          )}

          {['0', '1', '2'].includes(userType) && (
            <MenuItem
              active={urlParam === "companies" ? true : false}
              onClick={() => {
                navigate('/admin/companies')
                setUrlParam("companies")
              }}
              icon={<FaBuilding />}
              className="sidebar-menu-design sidebar-menu-design-custom"
            >
              Company
            </MenuItem>
          )}

          {['0'].includes(userType) && (
            <MenuItem
              active={urlParam === "users" ? true : false}
              onClick={() => {
                navigate('/admin/users')
                setUrlParam("users")
              }}
              icon={<FaUserCircle />}
              className="sidebar-menu-design sidebar-menu-design-custom"
            >
              User
            </MenuItem>
          )}
          {['0', '1', '2'].includes(userType) && (
            <MenuItem
              active={urlParam === "leases" ? true : false}
              onClick={() => {
                navigate('/admin/leases')
                setUrlParam("leases")
              }}
              icon={<MdClearAll />}
              className="sidebar-menu-design sidebar-menu-design-custom"
            >
              Lease
            </MenuItem>
          )}

          {['0', '1', '2'].includes(userType) && (
            <MenuItem
              active={urlParam === "wells" ? true : false}
              onClick={() => {
                navigate('/admin/wells')
                setUrlParam("wells")
              }}
              icon={<MdEditLocationAlt />}
              className="sidebar-menu-design sidebar-menu-design-custom"
            >
              Wells
            </MenuItem>
          )}
          {['0', '1', '2'].includes(userType) && (
            <MenuItem
              active={urlParam === "services" ? true : false}
              onClick={() => {
                navigate('/admin/services')
                setUrlParam("services")
              }}
              icon={<GrServices />}
              className="sidebar-menu-design sidebar-menu-design-custom"
            >
              Services
            </MenuItem>
          )}

          {['0'].includes(userType) && (

            <MenuItem
              active={urlParam === "jsa" ? true : false}
              onClick={() => {
                navigate('/admin/jsa')
                setUrlParam("jsa")
              }}
              icon={<FaEdit />}
              className="sidebar-menu-design sidebar-menu-design-custom"
            >
              JSA
            </MenuItem>
          )}

          {['0'].includes(userType) && (
            <MenuItem
              active={urlParam === "policies-and-procedures" ? true : false}
              onClick={() => {
                navigate('/admin/policies-and-procedures')
                setUrlParam("policies-and-procedures")
              }}
              icon={<IoDocumentsOutline />}
              className="sidebar-menu-design sidebar-menu-design-custom"
            >
              Policies and Procedures
            </MenuItem>
          )}

          <MenuItem
            onClick={() => setModalShow(true)}

            icon={<IoIosLogOut />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Log out
          </MenuItem>


        </Menu>
      </Sidebar>

      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div className="d-flex gap-2"><h4 className="mb-0 text-danger"><CgDanger /> </h4> <h6 className="my-auto">
          Are you sure you want to logout ?
        </h6></div> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => logOut()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal >
    </div>
  );
};

export default SidebarCustom;
