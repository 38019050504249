import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import { useRecoilState } from "recoil";
import { apiPost } from "../../util/ApiRequest";
import { isLoaderState } from "../../util/RecoilStore";


const TableComponent = ({ title, endPoint, headers, reloadData, searchObject }) => {
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);


    const [items, setItems] = useState([]);
    const [currentUrl, setCurrentUrl] = useState("");
    const [totalPage, setTotalPage] = useState(0);


    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('desc');



    useEffect(() => {
        const url = "";
        setCurrentUrl(url);
        getPaginatedUsers(url);
    }, [reloadData, searchObject]);

    const handlePageClick = (item) => {
        const url = `?page=${item.selected + 1}&limit=10`;
        getPaginatedUsers(url);
    };

    const getPaginatedUsers = (url) => {
        const ortObjects = {
            orderByColumn: sortField,
            orderBy: sortDirection
        };
        let mergedObject = { ...searchObject, ...ortObjects };
        setIsLoaderInfo(true);
        apiPost(
            endPoint + url,
            mergedObject,
            (res) => {
                setItems(res?.data?.results);
                setTotalPage(res?.data?.meta?.totalPages);
                setIsLoaderInfo(false);
            },
            (error) => {
                console.log(error);
                setIsLoaderInfo(false);
            }
        );
    };


    const handleSort = (column, sortDirection) => {
        setSortField(column.key)
        setSortDirection(sortDirection)
        const ortObjects = {
            orderByColumn: column.key,
            orderBy: sortDirection
        };
        let mergedObject = { ...searchObject, ...ortObjects };
        setIsLoaderInfo(true);
        apiPost(
            endPoint + currentUrl,
            mergedObject,
            (res) => {
                setItems(res?.data?.results);
                setTotalPage(res?.data?.meta?.totalPages);
                setIsLoaderInfo(false);
            },
            (error) => {
                console.log(error);
                setIsLoaderInfo(false);
            }
        );

    };


    return (
        <>
            <div className="my-dataTable">


                <div className="table-main-header bg-primary p-2">
                    <h5 className="my-auto   text-secondary  p-2">{title}</h5>
                </div>

                <div className="table-responsive">

                    <DataTable
                        className=""
                        columns={headers}
                        data={items}
                        pointerOnHover
                        striped
                        dense={false}
                        onSort={handleSort}

                    />

                </div>


            </div >
            <ReactPaginate
                autoWidth
                className="pagination-custom mt-4"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={totalPage}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </>
    );
};

TableComponent.defaultProps = {
    title: "default title",
    endPoint: "",
    headers: [],
    reloadData: false,
    searchObject: {},
};

export default TableComponent;
