import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import DataTable from 'react-data-table-component';
import { CiEdit } from "react-icons/ci";
import { FaEye } from "react-icons/fa6";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import DeleteItemModal from '../components/forms/DeleteItemModal';
import { apiPost } from '../util/ApiRequest';
import { ENDPOINTS } from '../util/EndPoint';
import { isLoaderState } from '../util/RecoilStore';

const ServicesRecords = () => {

  const [index, setIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const navigate = useNavigate();


  const columns = [

    {
      name: 'No',
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: 'Well',
      selector: (row) => row?.well?.name,
      sortable: false,
    },
    {
      name: 'Lease',
      selector: (row) => row?.well?.lease?.name,
      sortable: false,
    },
    {
      name: 'company',
      selector: (row) => row?.well?.lease?.company?.name,
      sortable: false,
    },

    {
      name: 'Action',
      selector: (row, index) => <div className='d-flex gap-2'>
        <button onClick={() => { navigate(`/admin/service-detail/${row.id}`) }} className='btn  icon-btn btn-primary'><FaEye /> </button>
        <button onClick={() => { navigate(`/admin/update-service?wellId=${row?.well?.id}&serviceId=${row?.id}`) }} className='btn  icon-btn btn-primary'><CiEdit /> </button>
        <button onClick={() => {
          setSelectedItem(row)
          setIndex(index)
          setDeleteModal(true)

        }} className='btn icon-btn btn-danger'><MdOutlineDelete /></button>
      </div>,
      sortable: false,
    },
  ];



  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [items, setItems] = useState([]);



  const { id } = useParams();
  const [itemId, setItemId] = useState(null);
  const [search, setSearch] = useState({
    wellId: id
  });

  useEffect(() => {
    getService();
    setItemId(id);
  }, [itemId]);




  useEffect(() => {
    getService();
  }, [search]);


  const getService = () => {
    if (itemId) {
      setIsLoaderInfo(true);
      apiPost(
        ENDPOINTS.ServiceByWell,
        search,
        (res) => {
          console.log(res?.data)
          setItems(res?.data);
          setIsLoaderInfo(false);
        },
        (error) => {
          console.log(error);
          setIsLoaderInfo(false);
        }
      );
    }
  };



  return (

    <div className='paginated-table'>

      <div className=' d-flex gap-3 mt-3'>




        <Form.Group className=" max-300 mb-3">
          <h6>Stat date</h6>
          <Form.Control type="date" placeholder="Stat date" required />

          <h6 className='mt-3'>End date</h6>
          <Form.Control type="date" required placeholder="End date" />
        </Form.Group>




      </div>



      <div className='text-end mb-3'>
        <button className='btn btn-primary me-2'>Download</button>
        <button className='btn btn-primary' onClick={() => { navigate(`/admin/create-service/${itemId}`) }} >Add service records</button>
      </div>


      <div className="my-dataTable">
        <div className="table-main-header bg-primary p-2">
          <h5 className="my-auto   text-secondary  p-2">{'Service records'}</h5>
        </div>

        <div className="table-responsive">

          <DataTable
            className=""
            columns={columns}
            data={items}
            pointerOnHover
            striped
            dense={false}

          />

        </div>


      </div >


      <DeleteItemModal
        url={ENDPOINTS.ServiceDelete}
        body={{
          serviceId: selectedItem.id
        }}
        reloadData={() => {
          setDeleteModal(false)
          getService();
        }}


        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete No ${index + 1} service?`}
        title="Delete service" />

    </div>
  )
}

export default ServicesRecords