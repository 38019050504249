import React from 'react';
import Form from 'react-bootstrap/Form';


const AtoDSelect = ({ label, name, setValue, defaultValue }) => {
    return (
        <>
            <label className="mb-2">{label}</label>
            <Form.Select onChange={(e) => setValue(e.target.value)} name={name} defaultValue={defaultValue}>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
            </Form.Select>
        </>
    )
}

export default AtoDSelect