import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";
import { useRecoilState } from "recoil";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";
import { isLoaderState, snakeBarState } from "../../util/RecoilStore";
import CompanySelect from "../global/CompanySelect";
import UserTypeSelect from "../global/UserTypeSelect";

const UpdateUser = ({ show, hideModal, reloadData, selectedItem }) => {

  // recoil
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

  const [userTypeState, setUserTypeState] = useState(selectedItem?.userType);
  const [isActive, setIsActive] = useState(selectedItem?.isActive);


  useEffect(() => {
    setUserTypeState(selectedItem?.userType);
    setIsActive(selectedItem?.isActive);
  }, [selectedItem]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    setIsLoaderInfo(true)

    apiPost(
      ENDPOINTS.UserUpdate,
      body,
      (res) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully  login",
        });
        setIsLoaderInfo(false)
        reloadData();

      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  };


  return (
    <>
      <Modal show={show} onHide={() => hideModal()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>

            <div className="mb-3">
              <label className="mb-2">First Name</label>
              <input
                className="form-control"
                placeholder="First Name"
                defaultValue={selectedItem?.firstName}
                name="firstName"
                type="text"
                required
              />
            </div>

            <div className="mb-3">
              <label className="mb-2">Last Name </label>
              <input
                placeholder="Last Name"
                defaultValue={selectedItem?.lastName}
                name="lastName"
                className="form-control"
                type="text"
                required
              />
            </div>


            <div className="mb-3">
              <label className="mb-2">User Name</label>
              <input
                placeholder="User Name"
                defaultValue={selectedItem?.userName}
                name="userName"

                className="form-control"

                type="text"
                required
                readOnly={true}
              />
            </div>
            <div className="mb-3">
              <label className="mb-2">Email</label>
              <input
                placeholder="email"
                defaultValue={selectedItem?.email}
                name="email"
                className="form-control"
                type="email"
                required
                readOnly={true}
              />
            </div>


            <div className="mb-3">
              <label className="mb-2">User type</label>

              {userTypeState >= 0 && (
                <UserTypeSelect
                  name={'userType'}
                  defaultValue={userTypeState}
                  setUserType={(id) => setUserTypeState(id)} />
              )}

            </div>

            {userTypeState == 2 && (
              <div className="mb-3">
                <label className="mb-2">Company</label>
                <CompanySelect name={'companyId'} setCompany={(id) => console.log(id)} />
              </div>
            )}
            <input type="hidden" name="isActive"
              value={isActive?.toString()} />
            <input type="hidden" name="userId"
              value={selectedItem.id} />


            <div className="mb-3">
              <Form.Check
                type={'checkbox'}
                label={`active`}
                checked={isActive == true ? true : false}
                onChange={(e) => { setIsActive(!isActive) }}
              />
            </div>

            <button className="btn btn-primary" type="submit">
              submit
            </button>
          </form >
        </Modal.Body >
      </Modal >
    </>
  );
};

export default UpdateUser;
