import React, { createRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import CustomStateInput from '../components/global/CustomStateInput';
import SignatureComponent from '../components/global/SignatureComponent';
import SketchFieldDemo from '../components/global/SketchFieldDemo';


const CreateJSA = () => {


    const [listOfSigns, setListOfSigns] = useState(["0"])
    const diagram = createRef(null)



    const [payload, setPayload] = useState({})
    const handleChange = (name, value) => {
        setPayload({
            ...payload, [name]: value
        })
    }


    const { id } = useParams();


    const [hazards, setHazards] = useState({
        pinchPoints: false,
        hotSurfaces: false,
        workingSurfaces: false,
        powerLines: false,
        equip: false,
        fallPotential: false,
        hazardousEnergy: false,
        heavyObjects: false,
        pwrTools: false,
        other: false,
    });

    const [deficiencies, setDeficiencies] = useState({
        equipInUse: false,
        valvesIdentified: false,
        fireExtinguisher: false,
        houseKeeping: false,
        triMonitor: false,
        barricades: false,
    })

    const [formData, setFormData] = useState({
        date: '',
        operator: '',
        location: '',
        activity: '',
        startTime: '',
        stopTime: '',
        tailgateTopic: '',
        customer: '',
        emergencySite: '',
        operatorPhone: '',
        diagram: '',
        comments: '',
        sign: '',
    });



    const handleSubmit = (event) => {
        event.preventDefault();
        const body = new FormData(event.target);
        // setIsLoaderInfo(true)

        // apiPost(
        //     ENDPOINTS.CompanyCreate,
        //     body,
        //     (res) => {
        //         setSnackBarInfo({
        //             snackStatus: true,
        //             snackColor: "bg-success",
        //             snackMsg: "successfully  login",
        //         });
        //         setIsLoaderInfo(false)
        //         reloadData();

        //     },
        //     (error) => {
        //         setSnackBarInfo({
        //             snackStatus: true,
        //             snackColor: "bg-danger",
        //             snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        //         });
        //         setIsLoaderInfo(false)
        //     }
        // );
    };

    // const handleChange = (name, value) => {
    //     setPayload({
    //         ...payload, [name]: value
    //     })
    // }
    // const handleSave = () => {
    //     const dataUrl = sigCanvas.current.toDataURL();
    //     console.log(dataUrl);
    // };

    return (
        <div className='container-fluid p-4 custom-overly'>
            <form onSubmit={handleSubmit} className=''>

                <h3 className='text-center mb-4'>Create JSA</h3>

                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="Date"
                            placeholder="Date"
                            type="date"
                            required={true}
                            name="date"
                            value={formData.date}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                date: val,
                            }))}
                        />
                    </div>

                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="Lead Person/Operator"
                            placeholder="Lead Person/Operator"
                            type="text"
                            required={true}
                            name="operator"
                            value={formData.operator}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                operator: val,
                            }))}
                        />
                    </div>

                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="Location"
                            placeholder="Location"
                            type="text"
                            required={true}
                            name="location"
                            value={formData.location}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                location: val,
                            }))}
                        />
                    </div>

                    <div className='col-lg-6 col-md-12'>

                        <CustomStateInput
                            label="Activity"
                            placeholder="Activity"
                            type="text"
                            required={true}
                            name="activity"
                            value={formData.activity}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                activity: val,
                            }))}
                        />
                    </div>


                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="Start Time"
                            placeholder="Start Time"
                            type="datetime-local"
                            required={true}
                            name="startTime"
                            value={formData.startTime}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                startTime: val,
                            }))}
                        />
                    </div>

                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="StopTime"
                            placeholder="Stop Time"
                            type="datetime-local"
                            required={true}
                            name="stopTime"
                            value={formData.stopTime}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                stopTime: val,
                            }))}
                        />

                    </div>

                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="Customer W/O *"
                            placeholder="Customer W/O *"
                            type="text"
                            required={true}
                            name="customer"
                            value={formData.customer}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                customer: val,
                            }))}
                        />
                    </div>

                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="Taligate Topic *"
                            placeholder="Taligate Topic *"
                            type="text"
                            required={true}
                            name="taligateTopic"
                            value={formData.taligateTopic}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                taligateTopic: val,
                            }))}
                        />
                    </div>

                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="Emergency Response Site *"
                            placeholder="Emergency Response Site *"
                            type="text"
                            required={true}
                            name="emergencySite"
                            value={formData.emergencySite}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                emergencySite: val,
                            }))}
                        />
                    </div>

                    <div className='col-lg-6 col-md-12'>
                        <CustomStateInput
                            label="Operator Name/Phone *"
                            placeholder="Operator Name/Phone *"
                            type="text"
                            required={true}
                            name="operatorPhone"
                            value={formData.operatorPhone}
                            setValue={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                operatorPhone: val,
                            }))}
                        />
                    </div>
                    <div className='col-12 mt-4'>
                        <h4>Do the following known hazards exists?</h4>
                    </div>



                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input name="pinchPoints"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.pinchPoints}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        pinchPoints: !hazards.pinchPoints,
                                    }))}
                                />
                                Line of Fire/Pinch Points
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="hotSurfaces"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.hotSurfaces}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        hotSurfaces: !hazards.hotSurfaces,
                                    }))}
                                />
                                Hazardous Atmosphere/Hot Surfaces
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="workingSurfaces"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.workingSurfaces}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        workingSurfaces: !hazards.workingSurfaces,
                                    }))}

                                />Walking Working Surfaces
                            </label>
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="powerLines"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.powerLines}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        powerLines: !hazards.powerLines,
                                    }))}

                                />Power Lines
                            </label>
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="equip"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.equip}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        equip: !hazards.equip,
                                    }))}
                                />
                                Vehicle / Hvy. Equip. Movement
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="fallPotential"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.fallPotential}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        fallPotential: !hazards.fallPotential,
                                    }))}
                                />
                                Fall Potential
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="hazardousEnergy"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.hazardousEnergy}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        hazardousEnergy: !hazards.hazardousEnergy,
                                    }))}
                                />
                                Hazardous Energy
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="heavyObjects"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.heavyObjects}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        heavyObjects: !hazards.heavyObjects,
                                    }))}
                                />
                                Moving Heavy Objects
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline"
                        ><label className="form-check-label">
                                <input
                                    name="pwrTools"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.pwrTools}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        pwrTools: !hazards.pwrTools,
                                    }))}
                                />
                                Rotating Machinery &amp; Pwr. Tools
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div
                            className="form-check form-check-inline">
                            <label className="form-check-label"
                            ><input
                                    name="other"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={hazards.other}
                                    onChange={() => setHazards(prevFormData => ({
                                        ...prevFormData,
                                        other: !hazards.other,
                                    }))}
                                />
                                Other
                            </label>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        <h6>Hazard identification & Mitigation: Identify the hazard(s) by #. Clearly identify the hazard(s) & corrective action necessary to mitigate the hazard(s).</h6>
                        <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Comments</Form.Label>
                            <Form.Control
                                value={formData.comments}
                                onChange={(e) => setFormData(prevFormData => ({
                                    ...prevFormData,
                                    comments: e.target.value,
                                }))}
                                name='comments'
                                placeholder='Comments' as="textarea" rows={3} />
                        </Form.Group>
                        <h6>The following checklist must be reviewed during a pre-job planning/tailgate meeting conducted at the work location. Any deficiencies shall be noted and this form will be kept on file in the office.</h6>
                    </div>

                </div>



                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="equipInUse"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={deficiencies.equipInUse}
                                    onChange={() => setDeficiencies(prevFormData => ({
                                        ...prevFormData,
                                        equipInUse: !deficiencies.equipInUse,
                                    }))}
                                />
                                Proper Protective Equipment In Use
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="valvesIdentified"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={deficiencies.valvesIdentified}
                                    onChange={() => setDeficiencies(prevFormData => ({
                                        ...prevFormData,
                                        valvesIdentified: !deficiencies.valvesIdentified,
                                    }))}

                                />
                                Suction/discharge valves identified
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label"
                            ><input
                                    name="fireExtinguiser"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={deficiencies.fireExtinguiser}
                                    onChange={() => setDeficiencies(prevFormData => ({
                                        ...prevFormData,
                                        fireExtinguiser: !deficiencies.fireExtinguiser,
                                    }))}
                                />
                                Fire Extinguisher(s) Checked
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="houseKeeping"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={deficiencies.houseKeeping}
                                    onChange={() => setDeficiencies(prevFormData => ({
                                        ...prevFormData,
                                        houseKeeping: !deficiencies.houseKeeping,
                                    }))}
                                />
                                Housekeeping —OK
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="triMonitor"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={deficiencies.triMonitor}
                                    onChange={() => setDeficiencies(prevFormData => ({
                                        ...prevFormData,
                                        triMonitor: !deficiencies.triMonitor,
                                    }))}
                                />
                                H2S/TRI Monitor - OK
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    name="barricades"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={deficiencies.barricades}
                                    onChange={() => setDeficiencies(prevFormData => ({
                                        ...prevFormData,
                                        barricades: !deficiencies.barricades,
                                    }))}
                                />
                                Barricades and/or Cones in place
                            </label>
                        </div>
                    </div>


                    <div className='col-12  mt-3'>
                        <h6>In the box below, diagram the work site. Indicate the approx. location of any overhead power lines, steam or flow lines, vehicle(s) location(s), and any other potential or additional hazards</h6>
                    </div>
                </div>
            
                <div className="row mt-3">
                    <div className='col-12'>

                        <div className='signature-com-box'>
                            {listOfSigns?.length > 0 &&
                                listOfSigns.map((item, index) => (
                                    <div key={index} className='signature-com'>
                                        <h2>Signature {index + 1}</h2>
                                        <SignatureComponent


                                        />
                                    </div>

                                ))}
                        </div>
                        <div className='text-center mb-4'>
                            <button type='button' className='btn btn-primary font-12' onClick={() => setListOfSigns([...listOfSigns, String(listOfSigns.length)])} >Add Signature</button>
                        </div>


                    </div>
                </div>

                <div className="row mt-3">
                    <div className='col-12 text-end'>
                        <button className='btn btn-danger me-2'>cancel</button>
                        <button className='btn btn-primary'>Add</button>
                    </div>
                </div>


            </form>
        </div>
    )
}

export default CreateJSA