import React, { useState } from 'react';
import {
    Col,
    Row
} from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { apiPost } from '../../util/ApiRequest';
import { ENDPOINTS } from '../../util/EndPoint';
import { isLoaderState, snakeBarState } from '../../util/RecoilStore';



const CustomDropzone = ({ type, reloadData, fileName }) => {

    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

    const [files, setFiles] = useState([]);



    const onFileSelect = (e) => {
        setFiles([...files, ...e?.target?.files]);
    }

    const deleteImage = (name, index) => {


    }


    const handleSubmit = (event) => {
        event.preventDefault();
        const body = new FormData(event.target);
        body.append('type', type);
        setIsLoaderInfo(true)

        apiPost(
            ENDPOINTS.PoliciesAndProceduresCreate,
            body,
            (res) => {
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-success",
                    snackMsg: "successfully  created",
                });
                setIsLoaderInfo(false)
                reloadData();
                setFiles([])
                document.querySelector('.file-upload-custom').value = '';

            },
            (error) => {
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
                });
                setIsLoaderInfo(false)
            }
        );
    };



    return (
        <>
            <form onSubmit={handleSubmit}>


                <div className='file-upload-custom-container'>

                    <label> Upload policies and procedures file
                        <input onChange={onFileSelect} type="file" required className='file-upload-custom' name={fileName} />
                    </label>
                </div>

                <button className='btn btn-primary mt-4' disabled={files?.length > 0 ? false : true} >Upload</button>
            </form>

            <Row className="px-3">
                {files.map((file, index) => {
                    return <Col key={index} className="mt-3 mr-2 border px-2 py-1" sm={2}
                    >
                        <i className="fa fa-times float-right" aria-hidden="true"
                            onClick={() => {
                                let newfiles = [...files]
                                newfiles.splice(index, 1)
                                setFiles([...newfiles])
                            }}
                        ></i>
                        {file.name}

                    </Col>
                })}
            </Row>
        </>

    )
}

export default CustomDropzone