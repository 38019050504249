

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_IMG_URL = process.env.REACT_APP_API_FILES_URL;
export const ENDPOINTS = {
  //User
  UserLogin: `${BASE_URL}api/auth/login`,
  UserPaginated: `${BASE_URL}api/users/user-paginated`,
  UserCreate: `${BASE_URL}api/users/create`,
  UserUpdate: `${BASE_URL}api/users/update`,
  UserDelete: `${BASE_URL}api/users/delete`,

  //company
  CompanyCreate: `${BASE_URL}api/company/create`,
  CompanyUpdate: `${BASE_URL}api/company/update`,
  CompanyDelete: `${BASE_URL}api/company/delete`,


  CompanyDropdown: `${BASE_URL}api/company/dropdown`,
  CompanyPaginated: `${BASE_URL}api/company/paginated`,
  UserCompany: `${BASE_URL}api/company/company-users`,


  //Lease
  LeasePaginated: `${BASE_URL}api/lease/paginated`,
  LeaseCreate: `${BASE_URL}api/lease/create`,
  LeaseDropdown: `${BASE_URL}api/lease/dropdown`,
  LeaseUpdate: `${BASE_URL}api/lease/update`,
  LeaseDelete: `${BASE_URL}api/lease/delete`,


  //well
  WellCreate: `${BASE_URL}api/well/create`,
  WellPaginated: `${BASE_URL}api/well/paginated`,
  WellSingle: `${BASE_URL}api/well/single/`,
  WellUpdate: `${BASE_URL}api/well/update`,
  WellDelete: `${BASE_URL}api/well/delete`,

  ServiceCreate: `${BASE_URL}api/service/create`,
  ServiceUpdate: `${BASE_URL}api/service/update`,
  ServiceByWell: `${BASE_URL}api/service/services-by-well`,
  ServiceSingle: `${BASE_URL}api/service/single/`,
  ServicePaginated: `${BASE_URL}api/service/paginated`,
  ServiceDeleteImage: `${BASE_URL}api/service/delete-image`,
  ServiceDelete: `${BASE_URL}api/service/delete`,


  PoliciesAndProceduresPaginated: `${BASE_URL}api/policies-and-procedures/paginated`,
  PoliciesAndProceduresCreate: `${BASE_URL}api/policies-and-procedures/create`,
  PoliciesAndProceduresDelete: `${BASE_URL}api/policies-and-procedures/delete`,


  ChartUsers: `${BASE_URL}api/chart/current-year-users`,
  ChartLeases: `${BASE_URL}api/chart/current-year-lease`,
  ChartCompany: `${BASE_URL}api/chart/current-year-company`,
  ChartWells: `${BASE_URL}api/chart/current-year-wells`,





};
