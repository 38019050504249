import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { CiEdit } from "react-icons/ci";
import { FaEye } from "react-icons/fa6";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import CreateWells from '../components/forms/CreateWells';
import DeleteItemModal from '../components/forms/DeleteItemModal';
import UpdateWells from '../components/forms/UpdateWells';


const JSA = () => {
  const navigate = useNavigate();

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const serviceOptions = [
    { value: 'com-1', label: 'Newest Well Added (ASC)' },
    { value: 'com-2', label: 'Newest Well Added (DESC)' },
    { value: 'com-3', label: 'Newest Service Record Added (ASC)' },
    { value: 'com-3', label: 'Newest Service Record Added (DESC)' }
  ]


  const optionsLease = [
    { value: 'com-1', label: 'Lease 1' },
    { value: 'com-2', label: 'Lease 2' },
    { value: 'com-3', label: 'Lease 3' }
  ]

  const optionsCompany = [
    { value: 'com-1', label: 'company 1' },
    { value: 'com-2', label: 'company 2' },
    { value: 'com-3', label: 'company 3' }
  ]

  return (
    <>
      <div className='paginated-table'>


        <div className='d-flex flex-wrap justify-content-between mb-3'>
          <h3>JSA</h3>
          <button onClick={() => {
            navigate('/admin/create-jsa')
          }} className='btn btn-primary'>Add JSA</button>
        </div>


        <div className='paginated-table-inner  table-responsive'>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>
                  <div className='filter-header'>
                    <h6 >Date</h6>
                  </div>
                </th>

                <th>
                  <div className='filter-header'>
                    <h6>Lead Operator</h6>
                  </div>
                </th>


                <th>
                  <div className='filter-header'>
                    <h6>Location</h6>
                  </div>
                </th>

                <th>
                  <div className='filter-header'>
                    <h6 >Customer</h6>
                  </div>
                </th>

                <th>
                  <div className='icon-header'>
                    <h6 >Detail</h6>
                  </div>
                </th>

                <th>
                  <div className='icon-header'>
                    <h6>Edit</h6>
                  </div>
                </th>

                <th>
                  <div className='icon-header'>
                    <h6>Delete</h6>
                  </div>
                </th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Mark</td>
                <td>Otto</td>
                <td> <button onClick={() => {
                  navigate('/admin/jsa-detail/5')
                }} className='btn  icon-btn btn-primary'><FaEye /> </button> </td>
                <td> <button onClick={() => setEditModal(true)} className='btn  icon-btn btn-primary'><CiEdit /> </button> </td>
                <td> <button onClick={() => setDeleteModal(true)} className='btn icon-btn btn-danger'><MdOutlineDelete /></button> </td>
              </tr>





            </tbody>
          </Table>

        </div>
      </div>

      <CreateWells show={addModal} hideModal={() => setAddModal(false)} />
      <UpdateWells show={editModal} hideModal={() => setEditModal(false)} />
      <DeleteItemModal
        show={deleteModal}
        hideModal={() => setDeleteModal(false)} text="Do you want to delete JSA 1?" title="Delete JSA" />
    </>
  )
}

export default JSA