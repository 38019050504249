import React from 'react';
import { Form } from 'react-bootstrap';

const CrankHolePosition = ({ name, label, setValue, defaultValue }) => {
    return (
        <>
            <label className="mb-2">{label}</label>
            <Form.Select
                onChange={(e) => setValue(e.target.value)}
                name={name}
                defaultValue={defaultValue}>
                <option value="Long">Long</option>
                <option value="Middle">Middle</option>
                <option value="Short">Short</option>
            </Form.Select>
        </>
    )
}

export default CrankHolePosition