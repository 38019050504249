import React from "react";
import Modal from "react-bootstrap/Modal";
import { useRecoilState } from "recoil";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";
import { isLoaderState, snakeBarState } from "../../util/RecoilStore";



const UpdateCompany = ({ show, hideModal, selectedItem, reloadData }) => {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  const handleSubmit = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    setIsLoaderInfo(true)

    apiPost(
      ENDPOINTS.CompanyUpdate,
      body,
      (res) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully  login",
        });
        setIsLoaderInfo(false)
        reloadData();

      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  };


  return (
    <>
      <Modal show={show} onHide={() => hideModal()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Update company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>

            <div className="mb-3">
              <label className="mb-2">Company Name</label>
              <input
                className="form-control"
                placeholder="company"
                type="text"
                defaultValue={selectedItem.name}
                name="name"

                required
              />
            </div>

            <div className="mb-3">
              <label className="mb-2">Phone</label>
              <input
                placeholder="phone"
                className="form-control"
                type="text"
                defaultValue={selectedItem.contact}
                name="contact"


                required
              />
            </div>

            <div className="mb-3">
              <label className="mb-2">Description</label>
              <textarea
                placeholder="enter description "
                className="form-control"
                required
                defaultValue={selectedItem.address}
                name="address"

              ></textarea>
            </div>

            <div className="mb-3">
              <label className="mb-2">UrI</label>
              <input
                placeholder="enter uri"
                className="form-control"
                type="text"
                required
                defaultValue={selectedItem.uri}
                name="uri"
              />
              <input type="hidden" value={selectedItem.id} name="companyId" />
            </div>


            <button className="btn btn-primary" type="submit">
              submit
            </button>
          </form >
        </Modal.Body >
      </Modal >
    </>
  );
};

export default UpdateCompany;
