
export const CheckImageExtensionAll = (filename) => {
    const imgType = ['txt', 'docx', 'pdf', 'apng', 'avif', 'gif', 'jpg', 'jpeg', 'png', 'svg', 'webp', 'mp4', 'wewbm', 'ogg'];
    var fileType = filename['name'].split('.').pop();
    const acceptFiles = imgType.includes(fileType)
    return acceptFiles;
};



export const checkUserType = (type) => {
    if (type == 0) {
        return 'Admin';
    }
    if (type == 1) {
        return 'Technician';
    }
    return 'User';
};