import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";
import { useRecoilState } from "recoil";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";
import { isLoaderState, snakeBarState } from "../../util/RecoilStore";
import CompanySelect from "../global/CompanySelect";
import UserTypeSelect from "../global/UserTypeSelect";


const CreateUser = ({ show, reloadData, hideModal }) => {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  const [userTypeState, setUserTypeState] = useState(null);

  const [isActive, setIsActive] = useState(false);





  const handleSubmit = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    setIsLoaderInfo(true)

    apiPost(
      ENDPOINTS.UserCreate,
      body,
      (res) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully  login",
        });
        setIsLoaderInfo(false)
        reloadData();

      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  };


  return (
    <>
      <Modal show={show} onHide={() => hideModal()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>

            <div className="mb-3">
              <label className="mb-2">First Name</label>
              <input
                className="form-control"
                placeholder="first Name"
                type="text"
                name="firstName"
                required
              />
            </div>

            <div className="mb-3">
              <label className="mb-2">Last Name </label>
              <input
                placeholder="last name"
                className="form-control"
                type="text"
                name="lastName"
                required
              />
            </div>


            <div className="mb-3">
              <label className="mb-2">User Name</label>
              <input
                placeholder="user name"
                className="form-control"
                type="text"
                required
                name="userName"
              />
            </div>
            <div className="mb-3">
              <label className="mb-2">Email</label>
              <input
                placeholder="email"
                className="form-control"
                type="email"
                name="email"
                required
              />
            </div>


            <div className="mb-3">
              <label className="mb-2">User type</label>
              <UserTypeSelect name="userType" setUserType={(id) => setUserTypeState(id)} />
            </div>

            {userTypeState == 2 && (
              <div className="mb-3">
                <label className="mb-2">Company</label>
                <CompanySelect name={'companyId'} setCompany={(id) => console.log(id)} />
              </div>
            )}

            <input type="hidden" name="isActive"
              value={isActive.toString()} />

            <div className="mb-3">
              <Form.Check
                type={'checkbox'}
                label={`active`}

                checked={isActive}
                onChange={(e) => { setIsActive(!isActive) }}
              />

            </div>



            <button className="btn btn-primary" type="submit">
              submit
            </button>
          </form >
        </Modal.Body >
      </Modal >
    </>
  );
};

export default CreateUser;
