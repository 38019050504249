import React, { useEffect, useState } from "react";
import Image from 'react-bootstrap/Image';
import { FaArrowRight } from "react-icons/fa6";
import { FiGrid } from "react-icons/fi";
import { Outlet } from "react-router-dom";
import logoImage from "../../assets/images/logo.png";
import { checkUserType } from "../../util/Helper";
import SidebarCustom from "../sidebar/Sidebar";

const AdminLayout = (props) => {
  const [toggled, setToggled] = useState(false);

  const [userType, setUserType] = useState('');
  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);


  return (
    <>
      <div>

        <div className="bg-primary">

          <div className="header-logos">
            <div>
              <Image src={logoImage} alt="logo" width={160} height={60} />
              <button className="sidebar-dashboard-logo" onClick={() => setToggled(!toggled)}> {toggled ? <FaArrowRight /> : <FiGrid />}</button>
            </div>
            <h5 className="text-white mb-0">Genesis Machine & Fabrication Portal</h5>
            <h4 className="user-profile-avatar">
              <span>Role</span>
              <span>{checkUserType(userType)}</span>
     
            </h4>

          </div>


        </div>
        <div className="main-layout">
          <SidebarCustom burgerShow={toggled} />
          <main className={` p-4 ${!toggled ? 'main-content' : 'main-content-close-sidebar'} `}>
            <Outlet />
          </main>
        </div>
      </div >
    </>
  );
};

export default AdminLayout;
