import React from "react";
import Modal from "react-bootstrap/Modal";
import { useRecoilState } from "recoil";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";
import { isLoaderState, snakeBarState } from "../../util/RecoilStore";
import CompanySelect from "../global/CompanySelect";


const CreateLease = ({ show, hideModal, reloadData }) => {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);



  const handleSubmit = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    setIsLoaderInfo(true)

    apiPost(
      ENDPOINTS.LeaseCreate,
      body,
      (res) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully  login",
        });
        setIsLoaderInfo(false)
        reloadData();

      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  };


  return (
    <>
      <Modal show={show} onHide={() => hideModal()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Add Lease</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>

            <div className="mb-3">
              <label className="mb-2">Lease Name</label>
              <input
                className="form-control"
                placeholder="Lease"
                type="text"
                required
                name="name"
              />
            </div>

            <div className="mb-3">
              <label className="mb-2">Company</label>
              <CompanySelect setCompany={(id) => console.log(id)} name={'companyId'} />
            </div>

            <button className="btn btn-primary" type="submit">
              submit
            </button>
          </form >
        </Modal.Body >
      </Modal >
    </>
  );
};

export default CreateLease;
