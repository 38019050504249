import React from 'react';

const Status = ({ status }) => {
    const divStyle = {
        backgroundColor: status === 'active' ? 'green' : 'red',
    };

    return (
        <div className='status-com' style={divStyle}>
            {status}
        </div>
    );
};

export default Status;
