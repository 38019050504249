import React from 'react';
import CustomDateInput from './CustomDateInput';
import CustomInput from './CustomInput';

const ServiceInfoForm = ({ wellObj }) => {
    return (
        <>
            <div className='container p-5 rounded-2 custom-boxshedow'>
                <div className='row'>
                    <div className='col-12  mb-3'>
                        <h4 className='text-center service-heading'>Info</h4>
                    </div>
                    <div className='col-md-6 col-sm-12 mb-3'>
                        <CustomInput
                            defaultValue={wellObj?.lease?.company?.name}
                            label="Customer"
                            placeholder="Customer"
                            type="text"
                            readOnly={true}
                            required={true}
                            name="Lease" />
                    </div>

                    <div className='col-md-6 col-sm-12 mb-3'>
                        <CustomInput
                            defaultValue={wellObj?.lease?.name}
                            label="Lease"
                            placeholder="Lease"
                            type="text"
                            readOnly={true}
                            required={true}
                            name="" />
                    </div>

                    <div className='col-md-6 col-sm-12 mb-3'>
                        <CustomDateInput
                            label="Date"
                            name="date"
                            type="date"
                            placeholder="select date"
                            required={true}
                            setValue={(val) => console.log(val)}
                        />
                    </div>


                    <div className='col-md-6 col-sm-12 mb-3'>


                        <input value={wellObj?.id} type='hidden' name='wellId' />
                        <CustomInput
                            defaultValue={wellObj?.name}
                            label="Well"
                            placeholder="Well"
                            type="text"
                            readOnly={true}
                            required={true}
                            name="" />

                    </div>
                </div>
            </div>

        </>
    )
}

export default ServiceInfoForm