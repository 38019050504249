import moment from 'moment';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CreateLease from '../components/forms/CreateLease';
import DeleteItemModal from '../components/forms/DeleteItemModal';
import UpdateLease from '../components/forms/UpdateLease';
import CompanySelect from '../components/global/CompanySelect';
import TableComponent from '../components/global/TableComponent';
import { ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';



const Services = () => {
  const navigate = useNavigate();


  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  const [reloadData, setReloadData] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const columns = [

    {
      name: 'Well',
      selector: (row) => row?.well?.name,
      sortable: false,
    },
    {
      name: 'Lease',
      selector: (row) => row?.well?.lease?.name,
      sortable: false,
    },
    {
      name: 'company',
      selector: (row) => row?.well?.lease?.company?.name,
      sortable: false,
    },
    {
      name: 'Inspection date',
      // selector: (row) => moment(row?.date).utc().format('YYYY-MM-DD') ,
      selector: (row) => <span>{moment(row?.date).utc().format('YYYY-MM-DD')}</span>,
      key: 'name',
      sortable: true,
    },
    {
      name: 'New service records',
      selector: (row) => <button onClick={() => { navigate(`/admin/create-service/${row?.id}`) }} className='btn  icon-btn btn-primary'><IoAddCircleSharp /> </button>,
      key: 'name',
      sortable: false,
    },

  ];






  const [search, setSearch] = useState({
    name: '',
    companyId: '',
  });



  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);


  return (
    <>

       <TableComponent
        title="Services"
        endPoint={ENDPOINTS.ServicePaginated}
        headers={columns}
        reloadData={reloadData}
        searchObject={search}
      />

      <CreateLease
        show={addModal}
        hideModal={() => setAddModal(false)}
        reloadData={() => {
          setAddModal(false)
          setReloadData(!reloadData)
        }}

      />
      <UpdateLease
        show={editModal}
        hideModal={() => setEditModal(false)}
        reloadData={() => {
          setEditModal(false)
          setReloadData(!reloadData)
        }}
        selectedItem={selectedItem} />

      <DeleteItemModal
        url={ENDPOINTS.LeaseDelete}
        body={{
          leaseId: selectedItem.id
        }}
        reloadData={() => {
          setDeleteModal(false)
          setReloadData(!reloadData)
        }}


        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectedItem.name} lease?`}
        title="Delete Lease" />

    </>
  )
}

export default Services