import React from 'react';
import Form from 'react-bootstrap/Form';
import CustomStateInput from './CustomStateInput';

const ServiceMotorInformation = ({ motorInfo, isCreated }) => {
    return (
        <div className='container mt-5  p-5 rounded-2 custom-boxshedow'>
            <div className='row'>
                <div className='col-12  mb-3'>
                    <h4 className='text-center service-heading'>Motor Information</h4>
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(motorInfo || isCreated) && (

                        <CustomStateInput
                            label="Manufacturer"
                            placeholder="Manufacturer"
                            type="text"
                            required={true}
                            name="motorInfo_manafacturer"
                            defaultValue={motorInfo?.manafacturer}

                        />
                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(motorInfo || isCreated) && (
                        <>

                            <label className="mb-2">Motor RPM</label>
                            <Form.Select defaultValue={motorInfo?.motorRpm} name="motorInfo_motorRpm" >
                                <options value="Conventional">900</options>
                                <option value="Beam">1200</option>
                            </Form.Select>
                        </>
                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(motorInfo || isCreated) && (

                        <CustomStateInput
                            label="Horsepower Rating"
                            placeholder="Horsepower Rating"
                            type="text"
                            required={true}
                            name="motorInfo_horsepowerRating"
                            defaultValue={motorInfo?.horsepowerRating}
                        />
                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(motorInfo || isCreated) && (

                        <CustomStateInput
                            label="Belt Size"
                            placeholder="Belt Size"
                            type="text"
                            required={true}
                            name="motorInfo_beltSize"
                            defaultValue={motorInfo?.beltSize}

                        />
                    )}
                </div>


                <div className='col-md-6 col-sm-12 mb-3'>
                    {(motorInfo || isCreated) && (

                        <CustomStateInput
                            label="Frame Size"
                            placeholder="Frame Size"
                            type="text"
                            required={true}
                            name="motorInfo_frameSize"
                            defaultValue={motorInfo?.frameSize}

                        />
                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(motorInfo || isCreated) && (

                        <CustomStateInput
                            label="Number of Belts"
                            placeholder="Number of Belts"
                            type="text"
                            required={true}
                            name="motorInfo_numberOfBelts"
                            defaultValue={motorInfo?.numberOfBelts}
                        />
                    )}
                </div>



                <div className='col-md-6 col-sm-12 mb-3'>
                    {(motorInfo || isCreated) && (

                        <CustomStateInput
                            label="Sheave Size"
                            placeholder="Sheave Size"
                            type="text"
                            required={true}
                            name="motorInfo_sheaveSize"
                            defaultValue={motorInfo?.sheaveSize}

                        />
                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(motorInfo || isCreated) && (

                        <CustomStateInput
                            label="Motor Shaft Size"
                            placeholder="Motor Shaft Size"
                            type="text"
                            required={true}
                            name="motorInfo_motorShaftSize"
                            defaultValue={motorInfo?.motorShaftSize}
                        />
                    )}
                </div>

            </div>
        </div>
    )
}

export default ServiceMotorInformation