import React from 'react';

const CustomStateInput = ({
  label,
  placeholder,
  type,
  required,
  name,
  value,
  setValue,
  defaultValue,
  readOnly
}) => {

  return (
    <div>
      <label className="mb-2">{label}</label>
      <input
        className={`form-control ${readOnly ? 'read-only-custom' : ''}`}
        placeholder={placeholder}
        type={type}
        name={name}
        required={required}
        readOnly={readOnly}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => setValue(e.target.vale)}
      />
    </div>
  );
};

CustomStateInput.defaultProps = {
  type: 'text',
  defaultValue: '',
  required: false,
  readOnly: false,
};

export default CustomStateInput;