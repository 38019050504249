import React from 'react'

const DetailJsa = () => {
    return (
        <div className='container-fluid'>

            <div className=''>
                <h3 className='mb-3'>Job Report Detail</h3>
                <div className="mb-4 card custom-overly">
                    <div className="p-4 col">
                        <div className="px-4 fs-18 row">
                            <div className="col-md-6">
                                <div className="row"><label className="">Date: </label><span className="fw-400">01/12/2024</span></div>
                                <div className="row"><label className="">Location: </label><span className="fw-400"> Sequoia</span></div>
                                <div className="row"><label className="">Start Time: </label><span className="fw-400"> 01/12/2024 08:01</span></div>
                                <div className="row"><label className="">Taligate Topic: </label><span className="fw-400"> Tripping surfaces</span></div>
                                <div className="row"><label className="">Emergency Response Site: </label><span className="fw-400"> Hermos road</span></div>
                            </div>
                            <div className="col-md-6">
                                <div className="row"><label className="">Lead Person/Operator: </label><span className="fw-400"> Diego Hernandez</span></div>
                                <div className="row"><label className="">Activity: </label><span className="fw-400"> Replace wrist pins</span></div>
                                <div className="row"><label className="">Stop Time: </label><span className="fw-400">01/12/2024 11:01</span></div>
                                <div className="row"><label className="">Customer W/O: </label><span className="fw-400"> Sequoia</span></div>
                                <div className="row"><label className="">Operator Name/Phone: </label><span className="fw-400"> 6</span></div>
                            </div>
                        </div>
                        <div className="p-2 fs-18 row">
                            <div className="col"><label className="">Do the following known hazards exists?</label></div>
                        </div>
                        <div className="px-4 fs-16 fw-12 row">
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="pinchPoints" disabled="" type="checkbox" className="form-check-input" />Line of Fire/Pinch Points</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="hotSurfaces" disabled="" type="checkbox" className="form-check-input" />Hazardous Atmosphere/Hot Surfaces</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="workingSurfaces" disabled="" type="checkbox" className="form-check-input" />Walking Working Surfaces</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="powerLines" disabled="" type="checkbox" className="form-check-input" />Power Lines</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="equip" disabled="" type="checkbox" className="form-check-input" />Vehicle / Hvy. Equip. Movement</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="fallPotential" disabled="" type="checkbox" className="form-check-input" />Fall Potential</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="hazardousEnergy" disabled="" type="checkbox" className="form-check-input" />Hazardous Energy</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="heavyObjects" disabled="" type="checkbox" className="form-check-input" />Moving Heavy Objects</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="pwrTools" disabled="" type="checkbox" className="form-check-input" />Rotating Machinery &amp; Pwr. Tools</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="other" disabled="" type="checkbox" className="form-check-input" />Other</label></div>
                            </div>
                        </div>
                        <div className="mt-4 fs-18 fw-12 row">
                            <div className="col-md-12"><label className="">Hazard identification &amp; Mitigation: Identify the hazard(s) by #. Clearly identify the hazard(s) &amp; corrective action necessary to mitigate the hazard(s).</label><span className="px-4 fw-400"></span></div>
                        </div>
                        <div className="mt-4 fs-18 row">
                            <div className="col"><label className="">The following checklist must be reviewed during a pre-job planning/tailgate meeting conducted at the work location. Any deficiencies shall be noted and this form will be kept on file in the office.</label></div>
                        </div>
                        <div className="px-4 fs-16 fw-12 row">
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="equipInUse" disabled="" type="checkbox" className="form-check-input" />Proper Protective Equipment In Use</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="valvesIdentified" disabled="" type="checkbox" className="form-check-input" />Suction/discharge valves identified</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="fireExtinguiser" disabled="" type="checkbox" className="form-check-input" />Fire Extinguisher(s) Checked</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="houseKeeping" disabled="" type="checkbox" className="form-check-input" />Housekeeping —OK</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="triMonitor" disabled="" type="checkbox" className="form-check-input" />H2S/TRI Monitor - OK</label></div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check form-check-inline"><label className="form-check-label"><input name="barricades" disabled="" type="checkbox" className="form-check-input" />Barricades and/or Cones in place</label></div>
                            </div>
                        </div>
                        <div className="mt-4 fs-18 row">
                            <div className="col"><label className="">In the box below, diagram the work site. Indicate the approx. location of any overhead power lines, steam or flow lines, vehicle(s) location(s), and any other potential or additional hazards</label></div>
                        </div>
                        <div className="row">
                            <div className="col"><img src="https://backend.genesismf.com/1705076013331-diagram.png" /></div>
                        </div>
                        <div className="mt-3 fs-18 row">
                            <div className="col"><label className="">I hereby acknowledge that I have reviewed this JSA/JSP and agree to follow all of the EHS requirements.</label></div>
                        </div>
                        <div className="mt-3 row">
                            <div className="signature-box col"><img src="https://backend.genesismf.com/1705076013335-sign0.png" /></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DetailJsa