import React from 'react';
import Select from 'react-select';

const UserTypeSelect = ({ setUserType, name, defaultValue }) => {

    const handleUserType = (selectedOption) => {
        setUserType(selectedOption.value)
    }

    const userTypeOptions = [
        { value: 0, label: 'Admin' },
        { value: 1, label: 'Technician' },
        { value: 2, label: 'User' },
    ]

    const defaultOption = userTypeOptions.find(option => option.value === defaultValue);

    return (
        <div>
            <Select
                value={defaultOption}
                name={name}
                className='select-custom'
                onChange={handleUserType}
                options={userTypeOptions} />
        </div>
    )
}

export default UserTypeSelect;
