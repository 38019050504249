import React from 'react'
import AtoDSelect from './AtoDSelect'
import LooseTightSelect from './LooseTightSelect'

const WristPinComponentForm = ({ wristPin, isCreated }) => {
    return (
        <div className='container  mt-5  p-5 rounded-2 custom-boxshedow'>
            <div className='row'>
                <div className='col-md-6 col-sm-12'>
                    <h5 className='text-center'>Left Side Wrist Pin </h5>
                    <div className=' mb-3'>
                        {(wristPin || isCreated) && (
                            <AtoDSelect
                                label="Seal"
                                setValue={(val) => console.log(val)}
                                name="wristPin_left_seal"
                                defaultValue={wristPin?.left?.seal}

                            />
                        )}
                    </div>

                    <div className=' mb-3'>
                        {(wristPin || isCreated) && (
                            <AtoDSelect
                                label="Bearing"
                                setValue={(val) => console.log(val)}
                                name="wristPin_left_bearing"
                                defaultValue={wristPin?.left?.bearing}

                            />
                        )}
                    </div>


                    <div className=' mb-3'>

                        {(wristPin || isCreated) && (
                            <LooseTightSelect
                                setValue={(val) => console.log(val)}
                                name="wristPin_left_nut"
                                label="Nut"
                                defaultValue={wristPin?.left?.nut}

                            />
                        )}
                    </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                    <h5 className='text-center'>Right Side Wrist Pin</h5>
                    <div className=' mb-3'>

                        {(wristPin || isCreated) && (
                            <AtoDSelect
                                setValue={(val) => console.log(val)}
                                label="Seal"
                                name="wristPin_right_seal"
                                defaultValue={wristPin?.right?.seal}

                            />
                        )}
                    </div>

                    <div className=' mb-3'>
                        {(wristPin || isCreated) && (
                            <AtoDSelect
                                setValue={(val) => console.log(val)}
                                label="Bearing"
                                name="wristPin_right_bearing"
                                defaultValue={wristPin?.right?.bearing}

                            />
                        )}

                    </div>
                    <div className=' mb-3'>
                        {(wristPin || isCreated) && (
                            <LooseTightSelect
                                setValue={(val) => console.log(val)}
                                name="wristPin_right_nut"
                                label="Nut"
                                defaultValue={wristPin?.right?.nut}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WristPinComponentForm